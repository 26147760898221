import React from 'react'


const Button = ({className, disabled, onClick, primary, dark, vdark, light, transparent, small, selected, isLoading, children}) => {
  let classes = 'text-white rounded-md py-3 cursor-pointer'
  let loadingIcon = ''
  if (primary || (!primary && !dark && !light && !transparent && !vdark)) {
    classes = `${classes} bg-gradient-to-r from-b4s-pink-1 to-b4s-pink-2 border border-b4s-pink-4 shadow shadow-b4s-pink-5/75`
  } 

  if (dark) classes = `${classes} hover:bg-b4s-blue-3 ${selected ? 'bg-b4s-blue-6' : 'bg-b4s-blue-4'}`
  if (vdark) classes = `${classes} hover:bg-b4s-blue-1/80 bg-b4s-blue-1`
  if (light) classes = `${classes} bg-transparent border border-white hover:bg-white/10`
  if (transparent) classes = `${classes} bg-transparent`
  if (small) classes = `${classes} min-w-0 px-4 text-xs font-medium`
  if (!small) classes = `${classes} text-sm w-full`

  if (className) classes = `${classes} ${className}`

  if (isLoading) {
    disabled = 'disabled'
    classes =  `${classes} cursor-wait opacity-80`
    loadingIcon = <i className="fal fa-sync fa-spin" />
  }
    
  return (
    <button className={classes} onClick={onClick} disabled={disabled} >{children} {loadingIcon}</button>
  )
}


export default Button
