import pjson from '../package.json'

const config = {
  'backUrl': process.env.REACT_APP_BACKEND_URL || 'http://localhost:3100/local',
  'frontUrl': process.env.REACT_APP_FRONTEND_URL || 'http://localhost:3000',
  'frontDomain': process.env.REACT_APP_FRONTEND_DOMAIN || 'localhost',
  'appVersion': pjson.version || '0.1.0',
  's3mp3dir': process.env.REACT_APP_S3_MP3_DIR || 'https://d10h1r9wkfsdwv.cloudfront.net/',
  's3covercraftdir': process.env.REACT_APP_S3_COVERCRAFT_DIR || 'https://covercraft.discobaleares.com/',
  'newBeatTime' : 2592000,
  'facebookPixel': process.env.REACT_APP_FB_PIXEL || '',
  'metricoolKey': process.env.REACT_APP_METRICOOL_KEY || '',
  'sendinblueKey': process.env.REACT_APP_SIB_KEY || 'wsssun5bo1kwlghufmua2ptfinvalid',
  'hotjarId': process.env.REACT_APP_HOTJAR_ID || '',
  'stripeKey': process.env.REACT_APP_STRIPE_KEY || 'pk_test_zTfcyXzqzK9I0orngyl2tb6R',
  'paypal': {
    'gateway': process.env.REACT_APP_PAYPAL_GW || 'https://www.sandbox.paypal.com/cgi-bin/webscr',
    'clientid': process.env.REACT_APP_PAYPAL_CLIENTID || 'AQwxzo4Vk20_CCt6kYCbXwEN1vzl7Ls1WOUEDTPcewecON1HfdDMdLjpr3xVoPxIM5vb96fFuBicVA-M',
    'intent': process.env.REACT_APP_PAYPAL_INTENT || 'subscription',
    'vault': process.env.REACT_APP_PAYPAL_VAULT || true,
  },
  'beatMoods': ['happy', 'dark', 'emotional', 'calm', 'party', 'chill', 'aggressive', 'sad'],
  'beatGenres': [{id:1, name: 'Latin Music'}, {id:2, name: 'Afrobeats'}, {id:3, name: 'Hip Hop / Rap'}, {id:4, name: 'R&B / Trapsoul'}, {id:5, name: 'Pop / Club'}, {id:6, name: 'Trap'}],
  'roles': {
    'guest':{
      'defaultRoute': '/login'
    },
    'admin':{
      'defaultRoute': '/logout'
    },
    'inactive': {
      'defaultRoute': '/user-area'
    },
    'active':{
      'defaultRoute': '/user-area'
    },
    'expired':{
      'defaultRoute': '/user-area'
    },
    'affiliate':{
      'defaultRoute': '/affiliate-area'
    },
    'producer':{
      'defaultRoute': '/producer-area'
    }
  },
  'framer': {
    'pageVariant': {
      'initial': {
        'x': -200,
        'opacity': 0
      },
      'animate': {
        'x': 0,
        'opacity': 1,
        'transition': {'duration': 0.5}
      },
      'exit': {
        'x': 200,
        'opacity': 0,
        'transition': {'duration': 0.5}
      }
    }
  }
}

export default config
