import * as types from '../actions/types'

const initialState = {
  prompt: '',                  // Associated with the current cover
  uploadedImageURL: '',        // URL of the uploaded image, if any
  chosenStyle: '',             // Style chosen for the current cover
  selectedVisuals: {},         // Visual overlays chosen for the current cover
  currentImage: '',            // Main image URL
  gallery: [],                 // Array containing generated covers with structure { url, prompt, id }
  loading: false,
  error: null,
}

const covercraftReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.COVERCRAFT_ADD_PROMPT:
    return {...state, prompt: action.payload.prompt}
    
  case types.COVERCRAFT_UPLOAD_IMAGE:
    return {...state, uploadedImageURL: action.payload.imageURL}
    
  case types.COVERCRAFT_ADD_STYLE:
    return {...state, chosenStyle: action.payload.style}
    
  case types.COVERCRAFT_ADD_VISUAL:
    // Assuming selectedVisuals is an object, we can spread previous visuals and add new ones
    return {...state, selectedVisuals: {...state.selectedVisuals, ...action.payload.visual}}
    
  case types.COVERCRAFT_SET_MAIN_IMAGE:
    return {...state, currentImage: action.payload.imageURL}
    
  case types.COVERCRAFT_GENERATE_IMAGE:
    return {
      ...state,
      gallery: [{url: action.payload.imageURL, prompt: state.prompt, id: action.payload.id}, ...state.gallery],
      currentImage: action.payload.imageURL,
      loading: false
    }
    
  case types.COVERCRAFT_FETCH_GALLERY:
    return {...state, gallery: action.payload, loading: false}
    
  case types.COVERCRAFT_ADD_TO_GALLERY:
    return {...state, gallery: [action.payload, ...state.gallery]}
    
  case types.COVERCRAFT_DELETE_FROM_GALLERY:
    return {...state, gallery: state.gallery.filter(image => image.id !== action.payload.id), loading: false}
    
  case types.COVERCRAFT_LOADING:
    return {...state, loading: true}
    
  case types.COVERCRAFT_ERROR:
    return {...state, loading: false, error: action.payload.error}
    
  default:
    return state
  }
}

export default covercraftReducer